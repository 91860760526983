exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asia-js": () => import("./../../../src/pages/asia.js" /* webpackChunkName: "component---src-pages-asia-js" */),
  "component---src-pages-australian-resources-js": () => import("./../../../src/pages/australian-resources.js" /* webpackChunkName: "component---src-pages-australian-resources-js" */),
  "component---src-pages-canada-js": () => import("./../../../src/pages/canada.js" /* webpackChunkName: "component---src-pages-canada-js" */),
  "component---src-pages-company-searches-js": () => import("./../../../src/pages/company-searches.js" /* webpackChunkName: "component---src-pages-company-searches-js" */),
  "component---src-pages-epo-eu-epc-js": () => import("./../../../src/pages/epo-eu-epc.js" /* webpackChunkName: "component---src-pages-epo-eu-epc-js" */),
  "component---src-pages-helpful-links-js": () => import("./../../../src/pages/helpful-links.js" /* webpackChunkName: "component---src-pages-helpful-links-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-ip-resources-js": () => import("./../../../src/pages/international-ip-resources.js" /* webpackChunkName: "component---src-pages-international-ip-resources-js" */),
  "component---src-pages-israel-js": () => import("./../../../src/pages/israel.js" /* webpackChunkName: "component---src-pages-israel-js" */),
  "component---src-pages-life-sciences-resources-js": () => import("./../../../src/pages/life-sciences-resources.js" /* webpackChunkName: "component---src-pages-life-sciences-resources-js" */),
  "component---src-pages-new-zealand-js": () => import("./../../../src/pages/new-zealand.js" /* webpackChunkName: "component---src-pages-new-zealand-js" */),
  "component---src-pages-online-news-js": () => import("./../../../src/pages/online-news.js" /* webpackChunkName: "component---src-pages-online-news-js" */),
  "component---src-pages-other-epc-countries-js": () => import("./../../../src/pages/other-epc-countries.js" /* webpackChunkName: "component---src-pages-other-epc-countries-js" */),
  "component---src-pages-patent-prior-art-searching-js": () => import("./../../../src/pages/patent-prior-art-searching.js" /* webpackChunkName: "component---src-pages-patent-prior-art-searching-js" */),
  "component---src-pages-russia-js": () => import("./../../../src/pages/russia.js" /* webpackChunkName: "component---src-pages-russia-js" */),
  "component---src-pages-subscription-resources-js": () => import("./../../../src/pages/subscription-resources.js" /* webpackChunkName: "component---src-pages-subscription-resources-js" */),
  "component---src-pages-united-kingdom-js": () => import("./../../../src/pages/united-kingdom.js" /* webpackChunkName: "component---src-pages-united-kingdom-js" */),
  "component---src-pages-united-states-resources-js": () => import("./../../../src/pages/united-states-resources.js" /* webpackChunkName: "component---src-pages-united-states-resources-js" */)
}

